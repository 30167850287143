/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Text, Subtitle, Title } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"SVATBY"}>
        <Column className="pb--10 pl--25 pr--25 pt--10" name={"prazdna_sekcee"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="pb--25" style={{"paddingBottom":null}}>
              
              <Image className="swp-T00-mobile --right" alt={""} src={"https://cdn.swbpg.com/o/2124/8e308098c208425aac7816f98e602e91.svg"} svg={false} sizes="100vw" style={{"maxWidth":45}} srcSet={""} content={null} RootClassName={"column__pic"}>
              </Image>

              <Text className="swp-T00-mobile text-box text-box--right mt--02" style={{"maxWidth":497,"marginTop":null}} content={"<br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"maxWidth":488}} content={"„Láska je nejen co dáváme, ale také co dostáváme.“ – John Wooden"}>
              </Text>

              <Subtitle className="subtitle-box mt--25" style={{"marginTop":null}} content={"<br>"}>
              </Subtitle>

              <Title className="title-box mt--06" style={{"maxWidth":620,"marginTop":null}} content={"SVATBY"}>
              </Title>

              <Image className="--right mt--40" alt={""} src={"https://cdn.swbpg.com/o/2124/0939ff1d9f92402f8a460fa8a39064fd.svg"} svg={false} sizes="100vw" style={{"maxWidth":45,"marginTop":null}} srcSet={""} content={null} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--right mt--02" style={{"maxWidth":429,"marginTop":null}} content={"Pokud Vás má práce oslovila a chcete váš den D zdokumentovat právě ode mne, napište mi..<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"maym3mzo0sa"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/62/img-1_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/62/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/62/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/62/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/62/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/62/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/62/img-2_s=350x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/62/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/62/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/62/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/62/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/62/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/62/img-3_s=350x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/62/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/62/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/62/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/62/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/62/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/62/img-1_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/62/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/62/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/62/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/62/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/62/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}